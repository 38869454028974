export type StringDictionary = { [key: string]: string };

export const PartnerIdToFriendlyNameMapping: StringDictionary = {
    "667b5449-287e-4d09-a076-7f21a8504934": "e-Invoicing Internal",
    "e7fdc318-c0a2-4255-846c-58101adb507d": "Pagero",
    "e973eb72-bedc-425a-bf1a-d561cac8a0c9": "SAP",
    "446e94b6-967a-4d4c-890d-ac483cda829f": "Modern Tax Receipts",
    "71d4f3dd-91cf-4253-a72f-8ce9d58d77b9": "Bing Ads",
    "a73d0127-608f-4941-b1bc-1f5b0d5c9d1c": "SAP RMCA AP",
    "4c8d6ae6-2583-4ca8-8adb-38e75e9d2df4": "MBS Dynamics D365",
    "336cba3e-afb9-429d-8ccf-62418a8d2d5e": "Skype"
}

export const FriendlyPartnerNameToPartnerIdMapping: StringDictionary = invertMapping(PartnerIdToFriendlyNameMapping);

export const CountryCodeToFriendlyNameMapping: StringDictionary = {
    "DK": "Denmark",
    "NO": "Norway",
    "SE": "Sweden",
    "HU": "Hungary",
    "FI": "Finland",
    "IN": "India",
    "DE": "Germany",
    "IT": "Italy",
    "VA": "Vatican City",
    "US": "USA",
    "RS": "Serbia",
    "SA": "Saudi Arabia",
    "CN": "China",
    "BR": "Brazil",
}
export const FriendlyCountryNameToCountryCodeMapping: StringDictionary = invertMapping(CountryCodeToFriendlyNameMapping);

export const ModeToFriendlyNameMapping: StringDictionary = {
    "uat": "UAT",
    "test": "Test",
    "default": "Live"
}
export const FriendlyNameToModeMapping: StringDictionary = invertMapping(ModeToFriendlyNameMapping);


export const ChannelToFriendlyNameMapping: StringDictionary = {
    "consumer": "Consumer",
    "commercial": "Commercial",
    "milanInvoicing": "MilanInvoicing"
}
export const FriendlyNameToChannelMapping: StringDictionary = invertMapping(ChannelToFriendlyNameMapping);

export const SalesMotionToFriendlyNameMapping: StringDictionary = {
    "VL": "VL",
    "EA": "EA",
    "professionalServices": "ProfessionalServices",
    "IC": "IC",
    "CTP": "CTP",
    "modernCommercial": "ModernCommercial",
    "modernConsumer": "ModernConsumer",
    "legacyConsumer": "LegacyConsumer",
    "none": "None",
}
export const FriendlyNameToSalesMotionMapping: StringDictionary = invertMapping(SalesMotionToFriendlyNameMapping);


export const SupplierIdToFriendlyNameMapping: StringDictionary = {
    "Pagero": "Pagero",
    "Pwc": "PWC",
    "Ariba": "Ariba",
    "Kpmg": "Kpmg",
    "Test": "Test",
    "Arvato": "Arvato",
}

export const DocumentTypeToFriendlyNameMapping: StringDictionary = {
    "invoice": "Invoice",
    "creditNote": "Credit Note",
    "paymentReceipt": "Payment Receipt",
    "debitNote": "Debit Note"
}

export const ScenarioToFriendlyNameMapping: StringDictionary = {
    "default": "Compliance",
    "aribaElective": "Ariba Elective"
}

export const FriendlySupplierNameToSupplierIdMapping: StringDictionary = invertMapping(SupplierIdToFriendlyNameMapping);

export const StatusToFriendlyNameMapping: StringDictionary = {
    "succeeded": "Succeeded",
    "inprogress": "In Progress",
    "failed": "Failed",
    "succeededWithWarning": "Warning",
    "cancelSucceeded": "Cancel Succeeded",
    "cancelFailed": "Cancel Failed",
    "cancelInProgress": "Cancel In Progress"
}

export const ChartTitleFriendlyNames: StringDictionary = {
    "channel": "Channel",
    "documentType": "Document Type",
    "mode": "Mode",
    "status": "Status",
    "errorCode": "Error Codes",
    "supplier": "Suppliers",
    "countryCode": "Country",
    "eInvPartnerId": "Calling Service",
    "salesMotion": "Sales Motion",
}

export const ErrorCodeandSubErrorCodeFriendlyNameMapping: StringDictionary = {
    "1000-1" : "Internal Document Id is not provided or invalid.",
    "1000-2" : "Tax classification code is not provided or invalid.",
    "1000-3" : "Document hash code is not provided or invalid.",
    "1000-4": "Unique document code is not provided or invalid.",
    "1000-5": "Qr code is not provided or invalid.",
    "1000-6": "Supply type is not provided or invalid.",
    "1000-7": "Purchase order number is not provided or invalid.",
    "1000-8": "Payment due date is not provided or invalid.",
    "1000-9": "Exchange rate date is not provided or invalid.",
    "1000-10": "Exchange rate is not provided or invalid.",
    "1000-11": "Document info level tax details not provided.",
    "1000-12": "Discount amount is not provided or invalid.",
    "1000-13": "Customer first name is not provided or invalid.",
    "1000-14": "Customer last name is not provided or invalid.",
    "1000-15": "Customer company name is not provided or invalid.",
    "1000-16": "Customer email is not provided or invalid.",
    "1000-17": "Customer tax Ids is not provided or invalid.",
    "1000-18": "Love code not provided or invalid.",
    "1000-19": "Donation code not provided or invalid.",
    "1000-20": "Common carrier code not provided or invalid.",
    "1000-21": "Customer financial info not provided or invalid.",
    "1000-22": "Seller tax Ids not provided or invalid.",
    "1000-23": "Government registration number not provided or invalid.",
    "1000-24": "Seller financial info not provided or invalid.",
    "1000-25": "Reference document info is not provided.",
    "1000-26": "Tax detail tax group Id is not provided or invalid.",
    "1000-27": "Tax detail tax product code is not provided or invalid.",
    "1000-28": "Line item description is not provided or invalid.",
    "1000-29": "Line item unit exchange price is not provided or invalid.",
    "1000-30": "Line item discount amount is not provided or invalid.",
    "1000-31": "Line item usage units is not provided or invalid.",
    "1000-32": "Line item usage type is not provided or invalid.",
    "1000-33": "Line item tax group Id is not provided or invalid.",
    "1000-34": "Line item SKU number is not provided or invalid.",
    "1000-35": "Line item tax product code is not provided or invalid.",
    "1000-36": "Line item product code is not provided or invalid.",
    "1000-37": "Line item service start date is not provided or invalid.",
    "1000-38": "Line item service end date is not provided or invalid.",
    "1000-39": "Line item disclaimer is not provided or invalid.",
    "1000-40": "Line item mandatory content is not provided or invalid.",
    "1000-41": "Line item tax detail tax group Id is not provided or invalid.",
    "1000-42": "Line item tax detail tax product code is not provided or invalid.",
    "1000-43": "Address id is not provided or invalid.",
    "1000-44": "Address line 1 is not provided or invalid.",
    "1000-45": "Address line 2 is not provided or invalid.",
    "1000-46": "Address line 3 is not provided or invalid.",
    "1000-47": "Address city is not provided or invalid.",
    "1000-48": "Address postal code is not provided or invalid.",
    "1000-49": "Address state name is not provided or invalid.",
    "1000-50": "Address state code is not provided or invalid.",
    "1000-51": "Address state number is not provided or invalid.",
    "1000-52": "Customer financial info Id is not provided or invalid.",
    "1000-53": "Customer financial account name is not provided or invalid.",
    "1000-54": "Customer financial account branch is not provided or invalid.",
    "1000-55": "Seller address id is not provided or invalid.",
    "1000-56": "Seller address line 1 is not provided or invalid.",
    "1000-57": "Seller address line 2 is not provided or invalid.",
    "1000-58": "Seller address line 3 is not provided or invalid.",
    "1000-59": "Seller address city is not provided or invalid.",
    "1000-60": "Seller address postal code is not provided or invalid.",
    "1000-61": "Seller address state name is not provided or invalid.",
    "1000-62": "Seller address state code is not provided or invalid.",
    "1000-63": "Seller address state number is not provided or invalid.",
    "1000-64": "Seller financial info id is not provided or invalid.",
    "1000-65": "Seller financial account name is not provided or invalid.",
    "1000-66": "Seller financial account branch is not provided or invalid.",
    "1000-67": "Seller contact info is not fully provided or invalid.",
    "1000-68": "Seller company name is not provided or invalid.",
    "1000-69": "Billing start date is not provided or invalid.",
    "1000-70": "Billing end date is not provided or invalid.",
    "1000-71": "Line item level tax details not provided.",
    "1000-72": "Line item details is missing",
    "1000-73": "Credit note reason is missing",
    "1000-74": "All Address line (1,2,3) & Postal code details should be provided & not invalid",
    "1000-75": "PublicSectorId is not invalid",
    "1100-1": "Document id length is invalid.",
    "1100-2": "Tax classification code length is invalid.",
    "1100-3": "Tax classification code description length is invalid.",
    "1100-4": "Document hash length is invalid.",
    "1100-5": "Unique document code length is invalid.",
    "1100-6": "Qr code length is invalid.",
    "1100-7": "Country code length is invalid.",
    "1100-8": "Credit reason length is invalid.",
    "1100-9": "Transaction currency code length is invalid.",
    "1100-10": "Exchange currency code length is invalid.",
    "1100-11": "Customer id length is invalid.",
    "1100-12": "Customer first name length is invalid.",
    "1100-13": "Customer last name length is invalid.",
    "1100-14": "Customer company name length is invalid.",
    "1100-15": "Customer email length is invalid.",
    "1100-16": "Love code length is invalid.",
    "1100-17": "Donation code length is invalid.",
    "1100-18": "Common carrier code length is invalid.",
    "1100-19": "Seller company name length is invalid.",
    "1100-20": "Company code length is invalid.",
    "1100-21": "Government registration number length is invalid.",
    "1100-22": "Reference document info document Id length is invalid.",
    "1100-23": "Reference document info internal document Id length is invalid.",
    "1100-24": "Reference document info description length is invalid.",
    "1100-25": "Tax detail tax group Id length is invalid.",
    "1100-26": "Tax detail tax product code length is invalid.",
    "1100-27": "Tax detail tax type length is invalid.",
    "1100-28": "Line item Id length is invalid.",
    "1100-29": "Line item description length is invalid.",
    "1100-30": "Line item usage type length is invalid.",
    "1100-31": "Line item tax group Id length is invalid.",
    "1100-32": "Line item SKU number length is invalid.",
    "1100-33": "Line item tax product code length is invalid.",
    "1100-34": "Line item product type length is invalid.",
    "1100-35": "Line item disclaimer length is invalid.",
    "1100-36": "Line item tax detail tax group Id length is invalid.",
    "1100-37": "Line item tax detail tax product code length is invalid.",
    "1100-38": "Line item tax detail tax type length is invalid.",
    "1100-39": "Payment instrument family length is invalid.",
    "1100-40": "Payment instrument type length is invalid.",
    "1100-41": "Customer address id length is invalid.",
    "1100-42": "Customer address type length is invalid.",
    "1100-43": "Customer address line 1/2/3 length is invalid.",
    "1100-44": "Customer address city length is invalid.",
    "1100-45": "Customer address postal code length is invalid.",
    "1100-46": "Customer address state name length is invalid.",
    "1100-47": "Customer address state number length is invalid.",
    "1100-48": "Customer address country code length is invalid.",
    "1100-49": "Customer financial info Id length is invalid.",
    "1100-50": "Customer financial info account number length is invalid.",
    "1100-51": "Customer financial info account name length is invalid.",
    "1100-52": "Customer financial info account branch length is invalid.",
    "1100-53": "Customer tax Ids length is invalid.",
    "1100-54": "Seller tax Ids length is invalid.",
    "1100-55": "Seller address id length is invalid.",
    "1100-56": "Seller address type length is invalid.",
    "1100-": "Seller address line 1/2/3 length is invalid.",
    "1100-58": "Seller address city length is invalid.",
    "1100-59": "Seller address postal code length is invalid.",
    "1100-60": "Seller address state name length is invalid.",
    "1100-61": "Seller address state number length is invalid.",
    "1100-62": "Seller address country code length is invalid.",
    "1100-63": "Seller financial info Id length is invalid.",
    "1100-64": "Seller financial info account number length is invalid.",
    "1100-65": "Seller financial info account name length is invalid.",
    "1100-66": "Seller financial info account branch length is invalid.",
    "1100-67": "Document description length is invalid.",
    "1100-68" : "Purchase order number length is invalid.",
    "1200-1": "Pre tax amount is invalid.",
    "1200-2": "Non taxable amount is invalid.",
    "1200-3": "Taxable amount is invalid.",
    "1200-4": "Discount amount is invalid.",
    "1200-5": "Tax amount is invalid.",
    "1200-6": "Total amount is invalid.",
    "1200-7": "Reference document info Pre tax amount is invalid.",
    "1200-8": "Reference document info Non taxable amount is invalid.",
    "1200-9": "Reference document info Taxable amount is invalid.",
    "1200-10": "Reference document info Tax amount is invalid.",
    "1200-11": "Reference document info Total amount is invalid.",
    "1200-12": "Tax details Pre tax amount is invalid.",
    "1200-13": "Tax details Non taxable amount is invalid.",
    "1200-14": "Tax details Taxable amount is invalid.",
    "1200-15": "Tax details Tax amount is invalid.",
    "1200-16": "Line item unit price amount is invalid.",
    "1200-17": "Line item unit exchange price amount is invalid.",
    "1200-18": "Line item Pre tax amount is invalid.",
    "1200-19": "Line item Tax amount is invalid.",
    "1200-20": "Line item Total amount is invalid.",
    "1200-21": "Line item Non taxable amount is invalid.",
    "1200-22": "Line item Taxable amount is invalid.",
    "1200-23": "Line item Discount amount is invalid.",
    "1200-24": "Line item Tax details Pre tax amount is invalid.",
    "1200-25": "Line item Tax details Non taxable amount is invalid.",
    "1200-26": "Line item Tax details Taxable amount is invalid.",
    "1200-27": "Line item Tax details Tax amount is invalid.",
    "1200-28": "Payment amount is invalid.",
    "1200-29": "One or more document level exchange amounts are invalid.",
    "1200-30": "Invoice total amount mismatch.",
    "1200-31": "Line item total amount is invalid and doesn't match with price*quantity calculation.",
    "1300-1": "Document type is not valid.",
    "1300-2": "Document date is not valid.",
    "1300-3": "Billing start date can not be greater than current date.",
    "1300-4": "Billing start date can not be greater than billing end date.",
    "1300-5": "Tax classification code is not valid. If not present at document level for IN, it should be present on all line items. ",
    "1300-": "Document hash is not valid.",
    "1300-7": "Unique document code is not valid.",
    "1300-8": "QR code is not valid.",
    "1300-9": "Supply type is not valid.",
    "1300-10": "Country code is not valid or not supported by E-Invoicing Service.",
    "1300-11": "Transaction currency code is not valid.",
    "1300-12": "Exchange currency code is not valid.",
    "1300-13": "Exchange rate date is not valid.",
    "1300-14": "Exchange rate is invalid.",
    "1300-15": "Tax calculation date is not valid.",
    "1300-16": "Tax amount value is not valid based on provided tax rate.",
    "1300-17": "Customer email is not a valid email address.",
    "1300-18": "Love code is not valid.",
    "1300-19": "Donation code is not valid.",
    "1300-20": "Common carrier code is not valid.",
    "1300-21": "Company code is not valid.",
    "1300-22": "Government registration number is not valid.",
    "1300-23": "Reference document info document date can not be greater than document date.",
    "1300-24": "Tax rate is not valid.",
    "1300-25": "Tax group Id is not valid.",
    "1300-26": "Tax product code is not valid.",
    "1300-27": "Tax type is not valid.",
    "1300-28": "Line item id is not valid.",
    "1300-29": "Line item tax group Id is not valid.",
    "1300-30": "Line item Sku number is not valid.",
    "1300-31": "Line item tax product code is.",
    "1300-32": "Line item product code is not valid.",
    "1300-33": "Line item product type is not valid.",
    "1300-34": "Line item transaction date is not valid.",
    "1300-35": "Line item service start date is not valid.",
    "1300-36": "Line item service end date is not valid.",
    "1300-37": "Line item tax percentage is not valid.",
    "1300-38": "Line item tax type is not valid..",
    "1300-39": "Payment due date is not valid.",
    "1300-40": "Payment instrument family or type is not valid.",
    "1300-41": "Customer address is not valid.",
    "1300-42": "Customer financial info is not valid.",
    "1300-43": "Seller address is not valid.",
    "1300-44": "Seller financial info is not valid.",
    "1300-45": "Tax Id is not valid.",
    "1300-46": "Document or Lineitem count invalid.",
    "1300-47": "Line item sequence number is not valid.",
    "1300-48": "Reference document info document id is invalid.",
    "1300-49": "Document data modification index is not valid.",
    "1300-50": "Tax exemption details is not valid.",
    "1300-51": "Line item tax details is not valid for line item..",
    "1300-52": "Document id is not valid.",
    "1300-53": "Customer id is not valid.",
    "1300-54": "Purchase order number is not valid.",
    "1300-55": "Payment mode is is not valid..",
    "1300-56": "Line item quantity is not valid for line item..",
    "1300-57": "Seller tax Id is not valid.",
    "1300-58": "Total amount on the document does not have expected sign.",
    "1300-59": "Reference document info document date is invalid and not within cutoff date for credit note processing.",
    "1400-1": "Duplicate request is being sent while one is already in processing.",
    "1400-2": "Duplicate request with previous request already completed successfully.",
    "1400-3": "Schema validation failed for request.",
    "1400-4": "Unable to complete request.",
    "1400-5": "QR Code Generation Failed.",
    "1400-6": "Payload size for request exceeded allowed limit.",
    "1400-7": "Buyer rejected the invoice.",
    "1400-8": "Building number must contain 4 digits.",
    "1400-9": "Buyer VAT registration number invalid.",
    "1400-10": "Credit note must contain reason.",
    "9000-1" : "Request has been abandoned by E-Invoicing.",
}

export const FriendlyStatusNameToStatusMapping: StringDictionary = invertMapping(StatusToFriendlyNameMapping);


function invertMapping(mapping: StringDictionary): StringDictionary {
    let invertedDict: StringDictionary = {};
    Object.entries(mapping).forEach(val => invertedDict[val[1]] = val[0]);
    return invertedDict;
}