import React, { useEffect, useState } from 'react';
import './Dashboard.css';
import DataGrid from '../../components/data-grid/DataGrid';
import { useMsal } from '@azure/msal-react';
import Configuration from '../../config/einvoicing-configuration';
import { AuthenticationResult } from '@azure/msal-common';
import { DashboardColumns } from './DashboardColumns';
import { CoherenceLoading } from '@coherence-design-system/controls';
import { PartnerIdToFriendlyNameMapping, CountryCodeToFriendlyNameMapping, DocumentTypeToFriendlyNameMapping, ModeToFriendlyNameMapping, StatusToFriendlyNameMapping, SupplierIdToFriendlyNameMapping, ChannelToFriendlyNameMapping, SalesMotionToFriendlyNameMapping } from '../../utils/FriendlyNameMappings';
import FilterGrid from '../../components/search-bar/FilterGrid';
import { DocumentSearchParams } from './DocumentSearchParams';
import { ErrorStateComponent } from '@coherence-design-system/controls';
import { ActionButton } from '@fluentui/react/lib/Button';
import { saveAs } from 'file-saver';
import { constructDocumentSearchUrl, constructExportSearchUrl, constructPostRequest, constructSearchPostRequest, getDefaultSearchParams } from '../../controller/dashboardController';
import { Toggle } from '@fluentui/react';

interface DashboardProps { }

function Dashboard(props: DashboardProps) {
    const { instance, accounts, inProgress } = useMsal();
    const [accessToken, setAccessToken] = useState('');
    const [searchParams, setSearchParams]: [DocumentSearchParams, React.Dispatch<React.SetStateAction<DocumentSearchParams>>] = useState(getDefaultSearchParams());
    const [searchResults, setSearchResults]: [any, React.Dispatch<React.SetStateAction<any>>] = useState([]);
    const [facetResults, setFacetResults]: [any[], React.Dispatch<React.SetStateAction<any[]>>] = useState<any[]>([]);
    const [initialFacetResults, setInitialFacetResults]: [any[], React.Dispatch<React.SetStateAction<any[]>>] = useState<any[]>([]);
    const [isLoading, setIsLoading]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(true);
    const [exactSearch, setExactSearch]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(true);
    const [useUTCTimeZone, setUseUTCTimeZone]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(true);
    const [isError, setIsError]: [boolean, React.Dispatch<React.SetStateAction<boolean>>] = useState(false);
    const [totalCount, setTotalCount]: [number, React.Dispatch<React.SetStateAction<number>>] = useState(0);

    function mapToDocumentRows(raw: any[]): any[] {
        return raw.map(row => {
            return {
                documentId: row.documentId,
                documentType: DocumentTypeToFriendlyNameMapping[row.documentType],
                status: StatusToFriendlyNameMapping[row.status],
                errorCode: row.errorCode,
                extReceiptId: row.externalReceiptId,
                countryCode: CountryCodeToFriendlyNameMapping[row.countryCode] ?? row.countryCode,
                companyCode: row.companyCode,
                eInvPartnerId: PartnerIdToFriendlyNameMapping[row.eInvPartnerId],
                startEventTime: getFormattedDate(new Date(row.startEventTime)),
                endEventTime: (!row.endEventTime || row.endEventTime.includes("0001")) ? undefined : getFormattedDate(new Date(row.endEventTime)),
                internalInvoiceNumber: row.internalInvoiceNumber,
                state: row.state,
                supplier: SupplierIdToFriendlyNameMapping[row.supplier],
                mode: ModeToFriendlyNameMapping[row.mode],
                documentGenerationDate: getFormattedDate(new Date(row.documentGenerationDate)),
                invoiceNumber: row.invoiceNumber,
                scenario: row.scenario,
                channel: ChannelToFriendlyNameMapping[row.channel],
                salesMotion: SalesMotionToFriendlyNameMapping[row.salesMotion],
                numberOfSubmissions: row.numberOfSubmissions
            };
        })
    }
    function getFormattedDate(date: Date): string {
        if (useUTCTimeZone) {

            return date.getUTCFullYear() + '-' + (date.getUTCMonth() + 1) + '-' + date.getUTCDate() + ' ' + String(date.getUTCHours()).padStart(2, '0') + ':' + String(date.getUTCMinutes()).padStart(2, '0') + ':' + String(date.getUTCSeconds()).padStart(2, '0')
        }
        else {

            return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + ' ' + String(date.getHours()).padStart(2, '0') + ':' + String(date.getMinutes()).padStart(2, '0') + ':' + String(date.getSeconds()).padStart(2, '0')
        }
    }

    // since errorCode facet is inside the errors object, update dimension for frontend code
    function reformatDimensions(json: any[]) {
        for (let item of json) {
            if (item.dimension === "errors/errorCode") { item.dimension = "errorCode" }
        }
    }

    async function getAccessToken() {
        if (accessToken === '') {
            const request = {
                ...Configuration.msalTokenRequest,
                account: accounts[0]
            };
            const result: AuthenticationResult = await instance.acquireTokenSilent(request);
            setAccessToken(result.idToken);
            return result.idToken
        }
        return accessToken;
    }
    function useDocumentSearch(params: DocumentSearchParams) {
        useEffect(() => {
            const searchUrl: string = constructDocumentSearchUrl(Configuration.eventServiceUrl, params.startTime, params.endTime, params.searchText);
            async function startDocumentSearch() {
                if (!ignore) {
                    try {
                        const requestParams = constructSearchPostRequest(params, exactSearch, await getAccessToken());
                        const result = await fetch(searchUrl, requestParams);
                        if (result !== undefined && result.ok) {
                            const resultJson = await result.json()
                            let mappedRows = mapToDocumentRows(resultJson.documents);
                            reformatDimensions(resultJson.facets);
                            setFacetResults(resultJson.facets)
                            if (initialFacetResults.length === 0) {
                                setInitialFacetResults(resultJson.facets)
                            }
                            setSearchResults(mappedRows);
                            if (resultJson.totalCount != null) {
                                setTotalCount(resultJson.totalCount);
                            }
                            setIsLoading(false);
                        }
                        else {
                            setIsLoading(false);
                            setIsError(true);
                        }
                    }
                    catch (ex) {
                        setIsLoading(false);
                        setIsError(true);
                    }
                }
            }

            let ignore = false;
            startDocumentSearch();
            return () => {
                ignore = true;
            }
        }, [params]);
    }

    async function getExportSearch() {
        const searchUrl: string = constructExportSearchUrl(Configuration.eventServiceUrl, searchParams.startTime, searchParams.endTime, searchParams.searchText);

        try {
            const requestParams = constructPostRequest(searchParams, await getAccessToken());
            const result = await fetch(searchUrl, requestParams);
            if (result !== undefined && result.ok) {
                var blob = await result.blob();
                saveAs(blob, `searchExport.csv`);
            }
        }
        catch (ex) {
        }
    }
    useDocumentSearch(searchParams);

    function refresh() {
        setSearchParams({ ...searchParams, page: 0 });
    }
    function onTimeZoneChange(event: React.MouseEvent<HTMLElement>, checked?: boolean) {
        setUseUTCTimeZone(checked ?? false)
        refresh()
    }

    function mergeFacets(facetResults: any[], initialFacetResults: any[]): any[] {
        for (const facet of facetResults) {
            let initialFacetVersion = initialFacetResults.find(a => a.dimension === facet.dimension);
            for (const key in initialFacetVersion.aggregatedTotals) {
                if (!(key in facet.aggregatedTotals)) {
                    facet.aggregatedTotals[key] = 0;
                }
            }
        }
        return facetResults;
    }

    function GetDateValue(useUTC: boolean, date: Date): Date {
        return useUTC ? new Date(date.getTime() + date.getTimezoneOffset() * 60000) : date;
    }
    if (isLoading) {
        return <CoherenceLoading primaryText='Loading Documents...' />
    }
    else if (!isError) {
        return (
            <div className="dashboard-container">
                <FilterGrid searchParams={searchParams} setSearchParams={setSearchParams} facetResults={mergeFacets(facetResults, initialFacetResults)} useUTC={useUTCTimeZone} setExactSearch={setExactSearch}></FilterGrid>
                <div className='dashboard-export'>
                    <h3>Found {totalCount} Documents:</h3>
                    <div style={{ display: 'flex' }}>
                        <Toggle label="Time Zone" onText="UTC" offText="Local" onChange={onTimeZoneChange} checked={useUTCTimeZone} inlineLabel={true} />
                        <ActionButton iconProps={{ iconName: 'Refresh' }} title="Refresh" onClick={refresh}>Refresh</ActionButton>
                        <ActionButton iconProps={{ iconName: 'Download' }} title="Export CSV" onClick={getExportSearch} >Export CSV</ActionButton>
                    </div>
                </div>
                <DataGrid
                    columns={DashboardColumns}
                    data={searchResults}
                    searchParams={searchParams}
                    setSearchParams={setSearchParams}
                    totalCount={totalCount}
                />
            </div>
        );
    }
    else {
        return (
            <ErrorStateComponent
                callToActionButton={{
                    ctaAriaLabel: 'Click here to refresh the page',
                    ctaHandler: function noRefCheck() {
                        window.location.reload();
                    },
                    ctaText: 'Refresh page'
                }}
                descriptionText="Check your internet connection, try refreshing the page or contact support."
                headingLevel="h2"
                headingText="There was an issue loading the page"
            />);
    }
}

export default Dashboard;