import { SelectionMode, Dropdown, IDropdownOption, DetailsListLayoutMode, ConstrainMode, IDetailsListStyles, IDetailsHeaderProps, IRenderFunction, IDetailsListProps, IDetailsRowStyles, DetailsRow, TooltipHost, ITooltipHostStyles, ITooltipProps, TooltipDelay, DirectionalHint } from "@fluentui/react";
import { DetailsList, IColumn } from '@fluentui/react';
import { Panel, PanelType } from '@fluentui/react/lib/Panel';
import "./DataGrid.css";
import React from 'react';
import { useMsal } from '@azure/msal-react';
import Configuration from '../../config/einvoicing-configuration';
import { AuthenticationResult } from '@azure/msal-common';
import { ErrorStateComponent } from '@coherence-design-system/controls';
import { CoherenceLoading } from '@coherence-design-system/controls';
import { IStyleSet, Label, ILabelStyles, Pivot, PivotItem } from '@fluentui/react';
import { ActivityItem, Icon, mergeStyleSets } from '@fluentui/react';
import { Toggle } from '@fluentui/react/lib/Toggle';
import { ActionButton, DefaultButton } from '@fluentui/react/lib/Button';
import { saveAs } from 'file-saver';
import { ColumnExtended } from "../../pages/dashboard/ColumnExtended";
import { DataGridProps } from "./DataGridProps";
import { constructGetDocumentBlobsUrl, constructGetDocumentEventsUrl, constructGetRequest } from "../../controller/dashboardController";
import { getTheme } from '@fluentui/react/lib/Styling';
import { FriendlyCountryNameToCountryCodeMapping, FriendlyNameToModeMapping, FriendlyPartnerNameToPartnerIdMapping, ScenarioToFriendlyNameMapping, ErrorCodeandSubErrorCodeFriendlyNameMapping } from "../../utils/FriendlyNameMappings";
import { useId } from "@fluentui/react-components";

function DataGrid(props: DataGridProps) {
    const [selectedItem, setSelectedItem] = React.useState<any>(null);
    const [documentEvents, setDocumentEvents] = React.useState<any>(null);
    const [accessToken, setAccessToken] = React.useState('');
    const [selectedScenario, setSelectedScenario] = React.useState('');
    const { instance, accounts, inProgress } = useMsal();
    const [isLoading, setIsLoading] = React.useState(true);
    const [showPriorityOnly, setShowPriorityOnly] = React.useState<boolean | undefined>(true);
    const [isError, setIsError] = React.useState(false);
    const [columns, setColumns] = React.useState<IColumn[]>(props.columns);
    const tooltipId = useId('tooltip');

    const classNames = mergeStyleSets({
        header: {
            margin: 0,
        },
        row: {
            flex: '0 0 auto',
        },
        root: {
            marginTop: '20px',
        },
        nameText: {
            fontWeight: 'bold',
        },
    });
    const gridStyles: Partial<IDetailsListStyles> = {
        root: {
            selectors: {
                '& [role=grid]': {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    flexGrow: 1,
                },
            }
        },
        headerWrapper: {
            flex: '0 0 auto',
        },
        contentWrapper: {
            flex: '1 1 auto',
            overflowY: 'auto',
            overflowX: 'auto',
        },
    };
    const onRowClick = (item: any) => {

        if (item && item != null) {
            GetDocumentEvents(item.documentId, item.countryCode, item.eInvPartnerId, item.mode);

            setSelectedScenario(documentEvents[0].scenario)
        }
        setSelectedItem(item);
        setIsOpen(true);
    };
    const onDismiss = () => {
        setSelectedItem(null);
        setIsOpen(false);
        setDocumentEvents(null);
        setShowPriorityOnly(true);
    };

    const [isOpen, setIsOpen] = React.useState(false);

    async function getAccessToken() {
        if (accessToken === '') {
            const request = {
                ...Configuration.msalTokenRequest,
                account: accounts[0]
            };
            const result: AuthenticationResult = await instance.acquireTokenSilent(request);
            setAccessToken(result.idToken);
            return result.idToken
        }
        return accessToken;
    }

    function getScenariosFromDocuments(documents: any[]): IDropdownOption<string>[] {
        return documents.map(a => ({ key: a.scenario, text: ScenarioToFriendlyNameMapping[a.scenario] }));
    }

    function GetDocumentEvents(documentId: string, countryCode: string, partnerId: string, mode: string) {

        const searchUrl: string = constructGetDocumentEventsUrl(Configuration.eventServiceUrl, documentId, FriendlyCountryNameToCountryCodeMapping[countryCode], FriendlyPartnerNameToPartnerIdMapping[partnerId], FriendlyNameToModeMapping[mode]);
        async function startDocumentSearch() {
            setIsError(false);
            setIsLoading(true);
            try {
                const requestParams = constructGetRequest(await getAccessToken());
                const result = await fetch(searchUrl, requestParams);
                if (result !== undefined && result.ok) {
                    setDocumentEvents(await result.json());
                    setIsLoading(false);
                }
                else {
                    setIsLoading(false);
                    setIsError(true);
                }
            }
            catch {
                setIsLoading(false);
                setIsError(true);
            }
        }
        startDocumentSearch();
    }
    const labelStyles: Partial<IStyleSet<ILabelStyles>> = {
        root: { marginTop: 10 },
    };
    function onToggleChange(ev: React.MouseEvent<HTMLElement>, checked?: boolean) {
        setShowPriorityOnly(checked)
    }

    async function GetDocumentData() {

        const searchUrl: string = constructGetDocumentBlobsUrl(Configuration.eventServiceUrl, selectedItem.documentId, FriendlyCountryNameToCountryCodeMapping[selectedItem.countryCode], FriendlyPartnerNameToPartnerIdMapping[selectedItem.eInvPartnerId], selectedScenario, FriendlyNameToModeMapping[selectedItem.mode]);

        setIsError(false);
        setIsLoading(true);
        try {
            const requestParams = constructGetRequest(await getAccessToken());
            const result = await fetch(searchUrl, requestParams);
            if (result !== undefined && result.ok) {
                var blob = await result.blob();
                saveAs(blob, `${selectedItem.documentId}.zip`);
                setIsLoading(false);
            }
            else {
                setIsLoading(false);
            }
        }
        catch {
            setIsLoading(false);
        }

    }

    function getErrorCodeDescription(item: string) {
        var errorCodes: string[] = item.split(',');
        var errorDescription: string ="";
        for (let i = 0; i < errorCodes.length; i++) {
            errorDescription = errorDescription + errorCodes[i] + ": " + ErrorCodeandSubErrorCodeFriendlyNameMapping[errorCodes[i]];
            if (i != errorCodes.length - 1) {
                errorDescription = errorDescription + ", ";
            }
        }
        return errorDescription;
    }

    function onRenderFooterContent() {
        return (
            <ActionButton onClick={GetDocumentData} iconProps={{ iconName: 'CloudDownload' }} >
                Download Data
            </ActionButton>
        );
    }
    function buildActivityItems(events: any[]) {

        var items: any[] = []
        events.map(function (event: any, index: number) {
            if (!showPriorityOnly || event.hasPriorityDisplay) {
                items.push(
                    {
                        key: index,
                        activityDescription: [
                            <span> {event.eventName}</span>,
                        ],
                        activityIcon: <Icon iconName={'Message'} />,
                        comments: [
                            <span>{event.displayContent}</span>
                        ],
                        timeStamp: event.eventTimeStamp,
                    },
                )
            }
        })
        return items
    }
    const onDropdownChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption | undefined): void => {
        if (item) {
            setSelectedScenario(item.key.toString())
        }
    };
    function getPanelHTML() {
        if (isLoading) {
            return (<CoherenceLoading primaryText='Loading Data...' />);
        }
        else if (!isError) {
            return (
                <div>
                    <Toggle label="Display Priority Events Only" defaultChecked inlineLabel onText="On" offText="Off" onChange={onToggleChange} />
                    {documentEvents.length > 1 && (
                        <Dropdown label="Scenario" onChange={(ev, it) => onDropdownChange(ev, it)} defaultSelectedKey={documentEvents[0].scenario} options={getScenariosFromDocuments(documentEvents)} dropdownWidth='auto' />
                    )}
                    <Pivot aria-label="Requests">
                        {documentEvents.filter((a: { scenario: string; }) => a.scenario == selectedScenario).map(function (request: any, index: number) {
                            return (
                                <PivotItem headerText={`Request - ${index + 1}`} >
                                    <Label styles={labelStyles}>Request Id - {request.requestId}</Label>
                                    {buildActivityItems(request.eInvoicingEvents).map((item: { key: string | number }) => (
                                        <ActivityItem {...item} key={item.key} className={classNames.root} />
                                    ))}
                                </PivotItem>);
                        })}
                    </Pivot>
                </div>);

        }
        else {
            return (<ErrorStateComponent
                descriptionText="Check your internet connection, try refreshing the page or contact support."
                headingLevel="h2"
                headingText="There was an issue loading the Document events"
            />);
        }
    }
    const onColumnHeaderClick = (ev?: React.MouseEvent<HTMLElement>, column?: ColumnExtended) => {
        if (column && column.isSortable) {
            const newColumns = [...columns];
            const currColumn = newColumns.filter(currCol => column.key === currCol.key)[0];
            const currColumnIndex = newColumns.indexOf(currColumn);
            newColumns[currColumnIndex] = {
                ...newColumns[currColumnIndex],
                isSorted: true,
                isSortedDescending: !currColumn.isSortedDescending,
            };
            newColumns.forEach((newCol, index) => {
                if (index !== currColumnIndex) {
                    newCol.isSorted = false;
                    newCol.isSortedDescending = true;
                }
            });
            setColumns(newColumns);
            props.setSearchParams({ ...props.searchParams, orderBy: currColumn.fieldName, desc: !currColumn.isSortedDescending, page: 0 })
        }
    };

    // Function to handle pagination
    const onPageChange = (page: number) => {
        // Update the state with the new page number
        props.setSearchParams({ ...props.searchParams, page: page })
    };
    function getPageSizeOptions(): IDropdownOption<number>[] {
        return [
            {
                key: 10,
                text: "10",
                data: 10
            },
            {
                key: 25,
                text: "25",
                data: 25
            },
            {
                key: 50,
                text: "50",
                data: 50
            },
            {
                key: 100,
                text: "100",
                data: 100
            }
        ]
    }

    const onRenderRow: IDetailsListProps['onRenderRow'] = (props: any) => {
        const customStyles: Partial<IDetailsRowStyles> = {};
        const theme = getTheme();
        if (props) {
            if (props.itemIndex % 2 === 1) {
                customStyles.root = {
                    backgroundColor: theme.semanticColors.listItemBackgroundHovered,
                };
            }
            return <DetailsRow {...props} styles={customStyles} />;
        }
        return null;
    };

    return (
        <>
            <div data-is-scrollable="true" style={{ overflowY: "scroll", flexGrow: 1, display: "flex", justifyContent: "center" }}>
                <DetailsList
                    items={props.data}
                    columns={columns}
                    onColumnHeaderClick={onColumnHeaderClick}
                    
                    onRenderItemColumn={(item, index, column) => {
                        if (column?.key == 'errorCode') {
                            if (!item.errorCodeDescription) {
                                item.errorCodeDescription = getErrorCodeDescription(item[column.fieldName ?? '']).toString();
                            }
                            return (
                                <TooltipHost
                                    content={item.errorCodeDescription}
                                    delay={TooltipDelay.zero}
                                    id={tooltipId}
                                    directionalHint={DirectionalHint.bottomCenter}
                                >
                                    {item[column.fieldName ?? '']}
                                </TooltipHost>
                            );
                        }
                        if (column) {
                            return <span>{item[column.fieldName ?? '']}</span>
                        }
                    }}
                    onItemInvoked={onRowClick}
                    compact={true}
                    selectionMode={SelectionMode.none}
                    layoutMode={DetailsListLayoutMode.fixedColumns}
                    constrainMode={ConstrainMode.unconstrained}
                    styles={gridStyles}
                    onRenderRow={onRenderRow}
                />
            </div>
            <div className='datagrid-pageoptions'>
                <div>
                    <button onClick={() => onPageChange(props.searchParams.page - 1)} disabled={props.searchParams.page === 0}>
                        Previous
                    </button>
                    <b>        Page:{props.searchParams.page + 1}/{Math.ceil(props.totalCount / props.searchParams.pageSize)}       </b>
                    <button onClick={() => onPageChange(props.searchParams.page + 1)} disabled={props.totalCount < (props.searchParams.page + 1) * props.searchParams.pageSize}>
                        Next
                    </button>
                </div>
                <div>
                    <Dropdown label='Page Size' placeholder={`${props.searchParams.pageSize}`} onChange={(_, it) => {
                        props.setSearchParams({ ...props.searchParams, pageSize: it?.data ?? 25 });
                    }} options={getPageSizeOptions()} dropdownWidth='auto' />
                </div>
            </div>
            {selectedItem != null && (
                <Panel
                    isLightDismiss
                    isOpen={isOpen}
                    type={PanelType.medium}
                    headerText={`Document ID - ${selectedItem.documentId}`}
                    closeButtonAriaLabel="Close"
                    onDismiss={onDismiss}
                    onRenderFooterContent={onRenderFooterContent}
                >
                    {getPanelHTML()}
                </Panel>)}
        </>
    );


}

export default DataGrid;